<template>
  <div id="why-exchange-az">
    <div class="main-container">
      <div class="header">چرا از ایکسچنج از خرید کنیم؟</div>
      <div class="wide-card-container">
        <WhyExchangeCard />
      </div>
      <div class="half-card-container">
        <WhyExchangeHalfCard
          v-for="data in why_exchange_card_data"
          :key="data.id"
          :image="data.image"
          :body="data.body"
          :header="data.header"
        />
      </div>
    </div>
  </div>
</template>
<script>
import WhyExchangeCard from "./Cards/WhyExchangeCard";
import WhyExchangeHalfCard from "./Cards/WhyExchangeHalfCard";

export default {
  name: "WhyExchangeAz",
  components: {
    WhyExchangeCard,
    WhyExchangeHalfCard
  },
  data() {
    return {
      why_exchange_card_data: [
        {
          id: 1,
          image: require("../../assets/images/cryptocurrency.svg"),
          header: " خرید آنی ارز دیجیتال",
          body:
            " یکی از مهمترین ویژگی های یک سایت خوب داشتن محیظی زیبا و کاربر پشند و ساده است، کافه ارز با افتخار این وِزگی را در اختیار شما قرار داده است "
        },
        {
          id: 2,
          image: require("../../assets/images/cash-flow.svg"),
          header: " نقد سریع درآمد ارزی",
          body:
            "یکی از مهمترین ویژگی های یک سایت خوب داشتن محیظی زیبا و کاربر پشند و ساده است، کافه ارز با افتخار این وِزگی را در اختیار شما قرار داده است"
        }
      ]
    };
  }
};
</script>
<style scoped>
#why-exchange-az {
  background-color: #fff;
  width: 100%;
  margin-top: 40px;
}
.main-container {
  padding: 0 10px;
  width: 100%;
  margin: 0 auto;
}

.header {
  font-family: "Vazir Medium FD";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 44px;
  text-align: right;
  color: #070d59;
  margin-bottom: 30px;
  text-align: center;
}

.wide-card-container {
}
.half-card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

@media (min-width: 996px) {
  #why-exchange-az {
    padding: 0 70px;
  }
  .main-container {
    padding: 0;
  }
  .header {
    font-size: 28px;
    text-align: start;
  }
}
@media (min-width: 1200px) {
  .half-card-container {
    justify-content: space-between;
  }
}
/* @media only screen and (max-width: 960px) {
  .half-card-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}
@media only screen and (max-width: 720px) {
  .half-card-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
@media only screen and (max-width: 576px) {
  .half-card-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
} */
</style>
