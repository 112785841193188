<template>
  <div id="weblog">
    <div class="service-container">
      <div class="header">وبلاگ</div>
      <div class="slider">
        <SliderCard :items="articles" />
      </div>
    </div>
  </div>
</template>
<script>
import SliderCard from "@/components/HomePage/Cards/SliderCard.vue";
import axios from "axios";
export default {
  name: "WebLog",
  components: {
    SliderCard
  },
  data() {
    return {
      articles: null
    };
  },
  methods: {
    async getArticles() {
      try {
        let response = await axios("/blog");
        this.articles = response.data;
      } catch (error) {
        console.log(error);
      }
    }
  },
  mounted() {
    this.getArticles();
  }
};
</script>
<style scoped>
#weblog {
  background-color: #fff;
  width: 100%;
  padding: 0 10px;
}
.service-container {
  padding: 0 10px;
  width: 100%;
  margin: 40px auto;
}
.header {
  font-family: "Vazir Medium FD";
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 44px;
  text-align: right;
  color: #070d59;
  margin-bottom: 60px;
}

@media (min-width: 992px) {
  #weblog {
    padding: 0 70px;
  }
}
</style>
