<template>
  <section id="services">
    <div class="services-container">
      <div class="header">خدمات ایکسچنج از در یک نگاه</div>
      <div class="body">
        <div class="card-container">
          <ServicesCard
            v-for="data in ServiceCardData"
            :key="data.id"
            :image="data.image"
            :header="data.header"
            :body="data.body"
            class="services-card"
          />
        </div>
        <img
          class="image"
          src="../../assets/images/rightillutration.svg"
          alt=""
        />
      </div>
    </div>
  </section>
</template>
<script>
import ServicesCard from "./Cards/ServicesCard";

export default {
  name: "SiteServices",
  components: {
    ServicesCard
  },
  data() {
    return {
      ServiceCardData: [
        {
          id: 1,
          image: require("../../assets/images/PSVouchers.png"),
          header: "خدمات رمز ارز ها",
          body:
            "پیپال یک سرویس واسط برای خرید های آنلاین است. با پی پال می توانید به راحتی از 80 درصد سایت های خارجی خرید نماید و از این سیستم برای انتقال پول استفاده کنید."
        },
        {
          id: 2,
          image: require("../../assets/images/PM.png"),
          header: "خدمات اسکریل",
          body:
            " پیپال یک سرویس واسط برای خرید های آنلاین است. با پی پال می توانید به راحتی از 80 درصد سایت های خارجی خرید نماید و از این سیستم برای انتقال پول استفاده کنید. "
        },
        {
          id: 3,
          image: require("../../assets/images/PSV.png"),
          header: "خدمات پرفکت مانی",
          body:
            " پیپال یک سرویس واسط برای خرید های آنلاین است. با پی پال می توانید به راحتی از 80 درصد سایت های خارجی خرید نماید و از این سیستم برای انتقال پول استفاده کنید. "
        },
        {
          id: 4,
          image: require("../../assets/images/FlagBrand=paypal,Mode=Light.png"),
          header: "خرید و فروش پی پال",
          body:
            " پیپال یک سرویس واسط برای خرید های آنلاین است. با پی پال می توانید به راحتی از 80 درصد سایت های خارجی خرید نماید و از این سیستم برای انتقال پول استفاده کنید. "
        },
        {
          id: 5,
          image: require("../../assets/images/FlagBrand=skrill,Mode=Light.png"),
          header: "خرید و فروش پی پال",
          body:
            " پیپال یک سرویس واسط برای خرید های آنلاین است. با پی پال می توانید به راحتی از 80 درصد سایت های خارجی خرید نماید و از این سیستم برای انتقال پول استفاده کنید. "
        },
        {
          id: 6,
          image: require("../../assets/images/webmoney.png"),
          header: "خدمات ووچر",
          body:
            " پیپال یک سرویس واسط برای خرید های آنلاین است. با پی پال می توانید به راحتی از 80 درصد سایت های خارجی خرید نماید و از این سیستم برای انتقال پول استفاده کنید. "
        }
      ]
    };
  }
};
</script>

<style scoped>
#services {
  background-color: #fff;
  width: 100%;
}
.services-container {
  width: 100%;
}
.header {
  font-family: "Vazir Medium FD";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  color: #070d59;
  margin-top: 40px;
  margin-bottom: 30px;
  text-align: center;
}
.card-container {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  z-index: 1;
  justify-content: center;
}
.servicesCard {
  margin-bottom: 30px;
}
.body {
  display: flex;
  align-items: center;
}
.image {
  display: none;
}
@media (max-width: 320px) {
  .card-container {
    height: fit-content;
  }
}
@media (min-width: 576px) {
  .image {
    display: block;
    position: absolute;
    opacity: 20%;
    width: 80%;
  }
}
@media (min-width: 768px) {
  #card {
    width: 33%;
  }
}
@media (min-width: 996px) {
  .services-container {
    padding: 0 58px;
  }
  .card-container {
  }
}
@media (min-width: 1200px) {
  #card {
    width: 230px;
  }
  .services-container {
  }
  .image {
    display: block;
    width: 45%;
    opacity: 1;
    position: unset;
  }
  .card-container {
    width: 50%;
  }
  .header {
    text-align: start;
    font-size: 28px;
  }
  .card-container {
    justify-content: start;
    width: 65%;
  }
}
</style>
