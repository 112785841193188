<template>
  <div id="card-container">
    <div class="item">
      <img src="../../../assets/images/Group.svg" alt="" />
      <div class="body">پشتیبانی ۲۴ ساعته</div>
    </div>
    <div class="item">
      <img src="../../../assets/images/shield.svg" alt="" />
      <div class="body">امنیت خرید و فروش</div>
    </div>
    <div class="item">
      <img src="../../../assets/images/clock.png" alt="" />
      <div class="body">سرعت در فرآیند</div>
    </div>
    <div class="item">
      <img src="../../../assets/images/loss.svg" alt="" />
      <div class="body">به صرفه ترین کارمزد</div>
    </div>
    <div class="item">
      <img src="../../../assets/images/touch.png" alt="" />
      <div class="body">سهولت استفاده</div>
    </div>
  </div>
</template>
<script>
export default {
  name: "WhyExchangeCard",
  data() {
    return {};
  }
};
</script>
<style scoped>
#card-container {
  width: 100%;
  background: linear-gradient(75.38deg, #5c6699 0%, #070d59 100%);
  border-radius: 20px;
  height: auto;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 0 85px;
  flex-wrap: wrap;
  padding: 20px 30px;
}
.item {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}
.body {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 25px;
  text-align: center;
  color: #ffffff;
  margin-top: 28px;
}
/* @media only screen and (max-width: 1400px) {
  }
  @media only screen and (max-width: 1140px) {
  }
  @media only screen and (max-width: 960px) {
    #card-container {
      flex-direction: column;
      padding: 30px 0;
      height: auto;
    }
  }
  @media only screen and (max-width: 720px) {
    #card-container {
      flex-direction: column;
      padding: 30px 0;
      height: auto;
    }
  }
  @media only screen and (max-width: 576px) {
    #card-container {
      flex-direction: column;
      padding: 30px 0;
      height: auto;
    }
  } */
</style>
