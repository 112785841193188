<template>
  <section id="hero">
    <div id="hero-container">
      <img class="hero-image" src="../../assets/images/auth-image.svg" alt="" />
      <div class="content">
        <div class="title-content">
          ایکسچنج از، مرکز خرید و فروش ارزهای دیجیتال و الکترونیک به صورت
          اتوماتیک و آنی
        </div>
        <div class="slogan">تضمین سرعت، امنیت و قیمت مناسب</div>
        <button @click="startNow" class="btn-start-now primary">
          همین الان شروع کنید
        </button>
      </div>
      <div class="danger-text">
        <img src="../../assets/images/danger.svg" alt="" />
        <p>
          اکسچنج از یک پلتفرم خرید و فروش ارز دیجیتال بوده و هیچ دخل و تصرفی در
          خرید و فروش دلار اسکناس ، حواله نداشته و نخواهد داشت.
        </p>
      </div>
    </div>
  </section>
</template>
<script>
import router from "../../router";

export default {
  name: "HeroSection",
  data() {
    return {};
  },
  methods: {
    startNow() {
      if (this.$store.getters.isLoggedIn) {
        this.$toast.info("شما قبلا وارد سایت شده اید");
        return false;
      }
      router.push("/login");
    }
  }
};
</script>
<style scoped>
#hero {
  width: 100%;
  min-height: 100vh;
  background: linear-gradient(75.38deg, #5c6699 0%, #070d59 100%);
  overflow: hidden;
}
#hero-container {
  margin: 0 auto;
  min-height: 100vh;
  /* min-width: 500px; */
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  padding: 0 20px;
}
.content {
  width: 100%;
  display: flex;
  flex-direction: column;
  z-index: 1;
}
.title-content {
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  color: #ffffff;
  text-align: center;
}
.slogan {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 31px;
  text-align: right;
  color: #ffffff;
  margin-top: 10px;
  text-align: center;
}
.btn-start-now {
  width: 55%;
  height: 40px;
  background: #ffffff;
  border: none;
  box-shadow: 0px 0px 15px rgba(255, 255, 255, 0.25);
  border-radius: 10px;
  font-weight: 500;
  font-size: 11px;
  line-height: 34px;
  color: #070d59;
  font-family: "Vazir Medium FD";
  background: #ffffff;
  box-shadow: 0px 0px 15px rgba(255, 255, 255, 0.25);
  cursor: pointer;
  align-self: center;
  margin-top: 20%;
}
.hero-image {
  width: 100%;
  position: absolute;
  opacity: 20%;
  top: 30%;
  left: 2%;
}
.danger-text {
  display: flex;
  align-items: center;
  position: absolute;
  right: 0;
  top: 85%;
  /* margin-top: 50px; */
  padding: 0 20px;
}
.danger-text p {
  color: #ffffff;
  padding-right: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 25px;
}

@media (min-width: 576px) {
  .content {
    width: 50%;
  }
  .title-content {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    color: #ffffff;
    text-align: start;
  }
  .slogan {
    text-align: start;
    font-size: 11px;
  }
  .hero-image {
    width: 50%;
    opacity: 1;
    top: 30%;
    left: 0%;
  }
  .danger-text {
    margin-top: 20px;
  }
}

@media (min-width: 768px) {
  .content {
    width: 50%;
  }
  .title-content {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    color: #ffffff;
    text-align: start;
  }
  .slogan {
    text-align: start;
    font-size: 15px;
  }
  .hero-image {
    width: 60%;
    opacity: 1;
    top: 123px;
    left: -59px;
  }
  .btn-start-now {
    font-size: 18px;
  }
}
@media (min-width: 992px) {
  #hero-container {
    padding: 0 70px;
  }
  .content {
    width: 50%;
  }
  .title-content {
    font-style: normal;
    font-weight: 500;
    font-size: 34px;
    color: #ffffff;
    text-align: start;
  }
  .slogan {
    text-align: start;
    font-size: 20px;
  }
  .hero-image {
    width: 60%;
    opacity: 1;
    top: 20%;
    left: -8%;
  }
  .btn-start-now {
    font-size: 22px;
  }
  .danger-text {
    padding: 0 70px;
  }
  .danger-text p {
    font-size: 16px;
  }
}
@media (min-width: 1200px) {
  #hero-container {
    padding: 0 70px;
  }
  .content {
    width: 50%;
  }
  .title-content {
    font-style: normal;
    font-weight: 500;
    font-size: 34px;
    color: #ffffff;
    text-align: start;
  }
  .slogan {
    text-align: start;
    font-size: 20px;
  }
  .hero-image {
    width: auto;
    opacity: 1;
    top: -189px;
    left: -203px;
  }
  .btn-start-now {
    font-size: 22px;
  }
  .danger-text p {
    font-size: 16px;
  }
}
</style>
