<template>
  <div id="card-container">
    <div class="item">
      <img class="image" :src="image" alt="" />
      <div class="content">
        <div class="header">{{ header }}</div>
        <div class="body">
          {{ body }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "WhyExchangeHalfCard",
  props: {
    image: String,
    header: String,
    body: String
  },
  data() {
    return {};
  }
};
</script>
<style scoped>
#card-container {
  width: 100%;
  height: auto;
  background: linear-gradient(75.38deg, #5c6699 0%, #070d59 100%);
  border-radius: 20px;
  margin-top: 20px;
}
.item {
  display: flex;
  flex-direction: column;
  padding: 30px 5px;
  align-items: center;
}
.image {
  margin-top: 25px;
  margin-right: 23px;
  width: 25%;
}
.header {
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 34px;
  text-align: center;
  color: #ffffff;
  margin-top: 25px;
  margin-right: 3px;
}
.body {
  font-family: "Vazir Medium FD";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 25px;
  text-align: right;
  color: #ffffff;
  margin-top: 13px;
  text-align: center;
}
.content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (min-width: 576px) {
  .item {
    flex-direction: column;
    padding: 30px 5px;
  }
  .card-container {
    width: 49%;
  }
}
@media (min-width: 996px) {
  #card-container {
    width: 49%;
  }
  .item {
    flex-direction: row;
    max-height: 220px;
  }
  .content {
    align-items: flex-start;
  }
}
@media (min-width: 1200px) {
  #card-container {
    width: 49%;
  }
  .item {
    flex-direction: row;
    max-height: 220px;
  }
  .content {
    align-items: flex-start;
  }
}
/* @media only screen and (max-width: 1400px) {
}
@media only screen and (max-width: 1140px) {
}
@media only screen and (max-width: 960px) {
  #card-container {
    height: auto;
  }
  .item {
    flex-direction: column;
    padding: 30px 5px;
  }
  .image {
    width: 40%;
    margin: auto;
  }
  .content {
  }
  .header {
    font-size: 20px;
    margin: auto;
    padding-top: 5px;
  }
  .body {
    font-size: 14px;
    margin: auto;
    padding: 8px 10px;
    width: 100%;
    height: 100%;
  }
}
@media only screen and (max-width: 720px) {
  #card-container {
    height: auto;
    width: 100%;
  }
  .item {
    flex-direction: column;
    padding: 30px 5px;
  }
  .image {
    width: 40%;
    margin: auto;
  }
  .content {
  }
  .header {
    font-size: 20px;
    margin: auto;
    padding-top: 5px;
  }
  .body {
    font-size: 14px;
    margin: auto;
    padding: 8px 10px;
    width: 100%;
    height: 100%;
  }
}
@media only screen and (max-width: 576px) {
  #card-container {
    height: auto;
    width: 100%;
  }
  .item {
    flex-direction: column;
    padding: 30px 5px;
  }
  .image {
    width: 25%;
    margin: auto;
  }
  .content {
  }
  .header {
    font-size: 20px;
    margin: auto;
    padding-top: 5px;
  }
  .body {
    font-size: 14px;
    margin: auto;
    padding: 8px 10px;
    width: 100%;
    height: 100%;
  }
} */
</style>
