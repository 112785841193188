<template>
  <div class="home">
    <HeroSection />
    <SiteServices />
    <WhyExchangeAz />
    <WebLog />
    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import HeroSection from "@/components/HomePage/HeroSection.vue";
import SiteServices from "@/components/HomePage/SiteServices.vue";
import WhyExchangeAz from "@/components/HomePage/WhyExchangeAz.vue";
import WebLog from "@/components/HomePage/WebLog.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "Home",
  components: {
    HeroSection,
    SiteServices,
    WhyExchangeAz,
    WebLog,
    Footer
  },
  data() {
    return {};
  }
};
</script>
<style scoped></style>

// SiteServices, WhyExchangeAz, WebLog, Footer
